import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Component, EventEmitter, HostListener, Inject, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { InteractionStatus, RedirectRequest, AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { StartPreloaderService } from './Core/Services/StartPreloader/start-preloader.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MonitoringService } from './Core/Services/Monitoring/MonitoringService';
import { LocalStorageService } from './Shared/Services/local-storage.service';
import { AppsApiFacade } from './Facade/ApiFacade/apps.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Playtika';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  isMobile:boolean = window.innerWidth <= 768;
  playti4u_url = environment.Playti4U_url;

  @Output() closeModalEmiter = new EventEmitter();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private startPreloaderService: StartPreloaderService,
    private storage:LocalStorageService,
    private appsApiFacade:AppsApiFacade,
    public modalHeaderService: BsModalService,
    private MonitoringService: MonitoringService
  ) {}

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      }, err => {console.log(err)})

      this.checkUnSavedAppsInLocalStorege();
  }

  checkUnSavedAppsInLocalStorege(){
    let appsToOrder = this.storage.getData("orderAppsList")
    let appsToDelete = this.storage.getData("deleteAppsList")
    let appsToFavorite = this.storage.getData("favoriteAppsList")

    if(appsToFavorite != null && appsToFavorite.length > 0){
      this.appsApiFacade.toggleFavoriteApps(appsToFavorite).subscribe((result) => {
        if(result == true){
          this.storage.removeData("favoriteAppsList")
        }
      })
    }

    if(appsToOrder != null && appsToOrder.length > 0){
      this.appsApiFacade.setOrder(appsToOrder).subscribe((result) => {
        if(result == true){
          this.storage.removeData("orderAppsList")
        }
      })
    }

    if(appsToDelete != null && appsToDelete.length > 0){
      this.appsApiFacade.removeApps(appsToDelete).subscribe((result) => {
        if(result == true){
          this.storage.removeData("deleteAppsList")
        }
      })
    }
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay == false){
      this.startPreloaderService.showPreloader();
    }
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          console.log(response)
        });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            console.log(response)
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  closeHeaderModalEmitter(){
    // this.closeModalEmiter.emit();
    this.modalHeaderService.hide();
  }

}
